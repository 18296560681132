
import { Delegates } from "@/api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type DelegateDocsType = {
  id: number;
  title: string;
  deleted_at: Date | null;
};

type DelegateDocs = {
  title: string;
  type: DelegateDocsType;
  url: string;
  originalName: string;
};

@Component
export default class DelegateDocuments extends Vue {
  @Prop({ default: false })
  isView!: boolean;
  @Prop()
  delegateId!: string;

  documents: DelegateDocs[] = [];
  key = 0;
  loading = false;

  get headers() {
    const headers: {
      text: string;
      value: string;
      align?: string;
      sortable?: boolean;
      width?: number;
    }[] = [
      {
        text: "Mandatory Documents",
        value: "title",
        sortable: false
      },
      {
        text: "Uploaded",
        value: "originalName",
        align: "center",
        sortable: false
      }
    ];
    if (!this.isView) {
      headers.push({
        text: "Upload",
        value: "actions",
        align: "center",
        sortable: false,
        width: 300
      });
    }
    return headers;
  }

  async mounted() {
    const types: DelegateDocsType[] = await Delegates.getDocumentsTypes();
    this.documents = types.map(type => {
      return {
        title: type.title,
        type,
        url: "",
        originalName: ""
      };
    });
    this.fetchDocuments();
  }

  @Watch("delegateId")
  fetch() {
    this.fetchDocuments();
  }

  async fetchDocuments() {
    this.loading = true;
    try {
      const docs: DelegateDocs[] = await Delegates.getDelegateDocuments(
        this.delegateId
      );

      this.documents.forEach(doc => {
        const file = docs.find(item => item.type?.id === doc.type?.id);
        if (file) {
          doc.originalName = file.originalName;
          doc.url = file.url;
        }
      });
    } catch (error) {
      //
    } finally {
      this.loading = false;
    }
  }

  async uploadFile(document: Blob, typeId: DelegateDocsType) {
    try {
      this.loading = true;
      await Delegates.uploadDelegateDocuments(
        this.delegateId,
        document,
        typeId.id
      );

      this.fetchDocuments();
      this.key += 1;
      this.$success("File uploaded successfully");
    } catch (e) {
      const err = e as any;
      this.$error(err);
    } finally {
      this.loading = false;
    }
  }
}
